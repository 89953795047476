@import url(https://fonts.googleapis.com/css?family=Great+Vibes);
@import url(https://fonts.googleapis.com/css?family=Quicksand);

.row18{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 3rem;

    @media only screen and (max-width: 1300px) {
        margin-top: 2rem;
    }

    @media only screen and (max-width: 700px) {
        margin-top: 1rem;
    }
}

.normalHeadingExperience{
    color: #000000;

    @media only screen and (max-width: 550px) {
        font-size: 2rem;
    }
}

.experienceBox{
    border-style: solid;
    border-width: 0.2rem;
    border-color: #91B1CF;
    border-radius: 2rem;
    margin: 2rem 5rem;

    @media only screen and (max-width: 1600px) {
        margin: 1rem 1rem;
        border-width: 0.1rem;
        border-radius: 2rem;
    }

    @media only screen and (max-width: 1000px) {
        border-width: 0.1rem;
        border-radius: 1rem;
    }

    @media only screen and (max-width: 550px) {
        margin: 2rem;
    }
}

.experienceBox:hover{
    background-color: #e6edf4;
}

.row19{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1rem 2rem;

    @media only screen and (max-width: 1000px) {
        margin: 0.5rem 1rem;
    }

    @media only screen and (max-width: 700px) {
        margin: 0.3rem 0.7rem;
    }

    @media only screen and (max-width: 550px) {
        flex-direction: column;
        margin: 0;
    }

}

.normalHeadingBodyExperience{
    font-family: Quicksand;
    font-size: 2.5rem;
    font-weight: 250; 
    margin-bottom: 0; 

    @media only screen and (max-width: 1900px) {
        font-size: 2rem;
    }

    @media only screen and (max-width: 1600px) {
        font-size: 1.7rem;
    }

    @media only screen and (max-width: 1300px) {
        font-size: 1.3rem;
    }

    @media only screen and (max-width: 1000px) {
        font-size: 1rem;
    }

    @media only screen and (max-width: 700px) {
        font-size: 0.7rem;
    }

    @media only screen and (max-width: 550px) {
        font-size: 1rem;
        margin: 0.3rem;
    }
}

.row20{
    display: flex;
    justify-content: left;
    margin: 1rem 2rem;

    @media only screen and (max-width: 1000px) {
        margin: 0.5rem 1rem;
    }

    @media only screen and (max-width: 700px) {
        margin: 0.3rem 0.7rem;
    }

    @media only screen and (max-width: 550px) {
        margin: 0;
        justify-content: center;
        
    }

}
.normalHeadingExperienceCompany{
    margin-top: 0;
    font-size: 2rem;

    @media only screen and (max-width: 1900px) {
        font-size: 1.5rem;
    }
    @media only screen and (max-width: 1300px) {
        font-size: 1.3rem;
    }

    @media only screen and (max-width: 1000px) {
        font-size: 1rem;
    }

    @media only screen and (max-width: 700px) {
        font-size: 0.7rem;
    }

    @media only screen and (max-width: 550px) {
        font-size: 1rem;
        margin: 0.3rem;
    }

}

.normalHeadingBodyExperienceTitle{
    text-decoration: underline;
}

.experienceContent{
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 2rem;

    @media only screen and (max-width: 1000px) {
        margin: 1rem;
    }

    @media only screen and (max-width: 700px) {
        margin: 0.7rem;
    }
}

.normalHeadingPoint{
    font-family: Quicksand;
    font-size: 2rem;
    margin-bottom: 1rem;

    @media only screen and (max-width: 1900px) {
        font-size: 1.5rem;
    }

    @media only screen and (max-width: 1300px) {
        font-size: 1.3rem;
    }

    @media only screen and (max-width: 1000px) {
        font-size: 1rem;
    }

    @media only screen and (max-width: 700px) {
        font-size: 0.7rem;
    }

    @media only screen and (max-width: 550px) {
        font-size: 1rem;
        margin: 0.3rem;
    }
}

