@import url(https://fonts.googleapis.com/css?family=Great+Vibes);
@import url(https://fonts.googleapis.com/css?family=Quicksand);

.additionalRow{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.educationBox{
    background-color: #C16E70;
    border-radius: 2rem;
    margin-right: 5rem;

    @media only screen and (max-width: 1600px) {
        margin-right: 1rem;
    }

    @media only screen and (max-width: 1000px) {
        border-radius: 1rem;
    }
}

.normalHeadingAdditionalBig{
    white-space: nowrap;
    color: white;
    margin: 1rem 3rem;

    @media only screen and (max-width: 1000px) {
        margin: 0.5rem 1.5rem;
    }

    @media only screen and (max-width: 550px) {
        white-space: normal;
        font-size: 1rem;
    }

    @media only screen and (max-width: 400px) {
        font-size: 1rem;
        margin: 0.25rem 0.75rem;
    }
}

.normalHeadingAdditional{
    color: white;
    margin: 1rem 3rem;
    font-size: 2.3rem;
    text-align: left;

    @media only screen and (max-width: 1900px) {
        font-size: 1.7rem;
    }

    @media only screen and (max-width: 1000px) {
        margin: 0.5rem 1.5rem;
        font-size: 1.3rem;
    }

    @media only screen and (max-width: 700px) {
        margin: 0.5rem 1.5rem;
        font-size: 0.7rem;
    }

    @media only screen and (max-width: 550px) {
        font-size: 0.7rem;
        margin: 0.7rem 0.75rem
    }

    @media only screen and (max-width: 400px) {
        font-size: 0.7rem;
        margin: 0.25rem 0.75rem;
    }
}

.scriptAdditional{

    margin-top: auto;
    margin-bottom: auto;

    @media only screen and (max-width: 1000px) {
        font-size: 2.2rem;
    }

    @media only screen and (max-width: 700px) {
        font-size: 2rem;
    }

    @media only screen and (max-width: 550px) {
        font-size: 1.5rem;
        margin: auto;
        margin-right: 1rem;
        margin-left: 1rem;
    }
}

.awardsBox{
    background-color: #C16E70;
    border-radius: 2rem;
    margin-left: 5rem;

    @media only screen and (max-width: 1600px) {
        margin-left: 1rem;
    }

    @media only screen and (max-width: 1000px) {
        border-radius: 1rem;
    }
}

.normalAdditionalAwards{
    white-space: pre;
}

.interestsBox{
    background-color: #C16E70;
    border-radius: 2rem;
    margin-right: 5rem;

    @media only screen and (max-width: 1600px) {
        margin-right: 1rem;
    }

    @media only screen and (max-width: 1000px) {
        border-radius: 1rem;
    }
}