@import url(https://fonts.googleapis.com/css?family=Great+Vibes);
@import url(https://fonts.googleapis.com/css?family=Quicksand);

.normalHeadingProjects{
    color: #000000;

    @media only screen and (max-width: 550px) {
        font-size: 2rem;
    }
}

.normalHeadingProjectTitle{
    color: #000000;
    @media only screen and (max-width: 900px) {
        font-size: 1.2rem;
    }

    @media only screen and (max-width: 700px) {
        font-size: 0.8rem;
    }

    @media only screen and (max-width: 550px) {
        font-size: 2rem;
    }
}

.row11{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.projects{
    background-color: #FFE3E3;
    margin-top: 1rem;
    padding-bottom: 3rem;
}

.projectRow{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    @media only screen and (max-width: 550px) {
        flex-direction: column;
        justify-content: center;
    }

}
.projectBox{
    width: 30%;
    background-color: white;
    margin: 0rem 2rem;
    border-radius: 2rem;

    @media only screen and (max-width: 1300px) {
        margin: 0rem 1rem;
    }

    @media only screen and (max-width: 900px) {
        margin: 0rem 0.5rem;
    }

    @media only screen and (max-width: 550px) {
        width: auto;
        margin: 1rem 5rem;
    }

    @media only screen and (max-width: 550px) {
        width: auto;
        margin: 0.5rem 2rem;
    }

}

.projectMain{
    height: 35rem;
    @media only screen and (max-width: 1900px) {
        height: 27rem;
    }

    @media only screen and (max-width: 1600px) {
        height: 30rem;
    }

    @media only screen and (max-width: 1600px) {
        height: 35rem;
    }

    @media only screen and (max-width: 1000px) {
        height: 20rem;
    }

    @media only screen and (max-width: 900px) {
        height: 15rem;
    }

    @media only screen and (max-width: 750px) {
        height: 18rem;
    }

    @media only screen and (max-width: 700px) {
        height: 13.5rem;
    }

    @media only screen and (max-width: 550px) {
        height: auto;
    }
}
.normalBodyProjects{
    font-family: Quicksand;
    font-size: 1.7rem;
    font-weight: 250;  
    text-align: left;
    margin: 2rem;
    list-style-type: disc;
    @media only screen and (max-width: 1900px) {
        font-size: 1.5rem;
    }
  
    @media only screen and (max-width: 1600px) {
        font-size: 1.3rem;
    }
  
    @media only screen and (max-width: 1000px) {
      font-size: 1rem;
      margin: 1rem;
    }

    @media only screen and (max-width: 900px) {
        font-size: 0.8rem;
    }

    @media only screen and (max-width: 700px) {
        font-size: 0.55rem;
    }

    @media only screen and (max-width: 550px) {
      font-size: 1rem;
    }
}

.rowTags{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 1rem;
    @media only screen and (max-width: 1300px) {
    margin: 0.5rem;
    }

    @media only screen and (max-width: 900px) {
        justify-content: center;
    }

}

.projectTag{
    background-color: #C16E70;
    width: 30%;
    border-radius: 0.5rem;

    @media only screen and (max-width: 900px) {
        width: 30%;
        margin: 0 0.3rem;
        border-radius: 0.3rem;
    }

    @media only screen and (max-width: 550px) {
        width: 30%;
        margin: 0 0.3rem;
    }
}

.normalBodyProjectsTags{
    color: white;
    margin: 0.5rem;
    text-align: center;

    @media only screen and (max-width: 1900px) {
        font-size: 1rem;
    }

    @media only screen and (max-width: 1300px) {
        font-size: 0.8rem;
    }

    @media only screen and (max-width: 900px) {
        margin: 0.2rem;
        font-size: 0.6rem;
    }

    @media only screen and (max-width: 700px) {
        margin: 0.2rem;
        font-size: 0.4rem;
    }
    
    @media only screen and (max-width: 550px) {
        font-size: 0.8rem;
        margin: 0.5rem;
    }

    @media only screen and (max-width: 450px) {
        font-size: 0.7rem;
        margin: 0.5rem;
    }
    
}

.imgLine{
    height: 2px;
    background-color: #FFE3E3;
    width: 100%;
    margin-top: 3rem;
}

.row12{
    display: flex;
    justify-content: center;
}
.projectImage{
    width: 100%;
    border-radius: 0rem 0rem 2rem 2rem;
}

