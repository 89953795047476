@import url(https://fonts.googleapis.com/css?family=Great+Vibes);
@import url(https://fonts.googleapis.com/css?family=Quicksand);

.row4{
    margin-top: 5rem;
    display: flex;
    flex-direction: row;
    background-color: #C16E70;
    justify-content: space-evenly;

    @media only screen and (max-width: 1900px) {
        margin-top: 10rem;
    }

    @media only screen and (max-width: 1600px) {
        margin-top: 5rem;
    }

    @media only screen and (max-width: 1000px) {
        margin-top: 2rem;
    }

    @media only screen and (max-width: 550px) {
        display: none;
    }
}

.normalHeading{
    font-family: Quicksand;
    font-size: 4rem;
    font-weight: 50;
    margin-top: 1rem;
    margin-bottom: 1rem;

    @media only screen and (max-width: 1900px) {
        font-size: 3rem;
    }

    
    @media only screen and (max-width: 1600px) {
        font-size: 2rem;
    }

    @media only screen and (max-width: 1000px) {
        font-size: 1.5rem;
    }

    @media only screen and (max-width: 700px) {
        font-size: 1rem;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
}

.normalHeadingBar{
    color: #FFFFFF;
}

.toolBar{
    width: 30%;
    text-decoration: none;
}

.toolBar:hover{
    background-color: #D09092;
    border-radius: 1rem;
    cursor: pointer;
}


.row5{
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media only screen and (max-width: 550px) {
        margin-top: 5rem;
    }
}

.lineEmbellish{
  height: 1px;
  width: 10rem;
  background: black;
  margin: auto;

@media only screen and (max-width: 1000px) {
    width: 7rem;
}

@media only screen and (max-width: 700px) {
    width: 3rem;
}

@media only screen and (max-width: 550px) {
    width: 4rem;
}
}

.lineEmbellishLeft{
    margin-right: 2rem;
    @media only screen and (max-width: 550px) {
        margin-right: 1rem;
    }
}

.lineEmbellishRight{
    margin-left: 2rem;
    @media only screen and (max-width: 550px) {
        margin-left: 1rem;
    }
}

.normalHeadingAbout{
    color: #000000;

    
    @media only screen and (max-width: 550px) {
        font-size: 2rem;
    }
}

.row6{
    display: flex;
    justify-content: right;
    height: 0;
}
.cornerEmbellish{
    width: 10rem;
    height: 10rem;
    position: relative;
    border-color: #FFE3E3;
    border-style: solid;

    @media only screen and (max-width: 1600px) {
        width: 5rem;
        height: 5rem;
    }

    @media only screen and (max-width: 1000px) {
        width: 2rem;
        height: 2rem;
    }

    @media only screen and (max-width: 700px) {
        width: 2rem;
        height: 2rem;
    }

    @media only screen and (max-width: 550px) {
        display: none;
    }

}

.cornerEmbellishRight{
    border-width: 0.2rem 0.2rem 0 0;
    margin-top: -3.5rem;
    margin-right: 3rem;

    @media only screen and (max-width: 1900px) {
        margin-top: -3rem;
    }

    @media only screen and (max-width: 1600px) {
        margin-top: -2.5rem;
    }

    @media only screen and (max-width: 1000px) {
        margin-top: -2rem;
        margin-right: 2rem;
        border-width: 0.1rem 0.1rem 0 0;
    }

    @media only screen and (max-width: 700px) {
        margin-top: -1rem;
        margin-right: 2rem;
    }
    
}

.cornerEmbellishLeft{
    border-width:0 0 0.2rem 0.2rem;
    margin-top: -3.5rem;
    margin-left: 3rem;

    @media only screen and (max-width: 1900px) {
        margin-top: -3rem;
    }

    @media only screen and (max-width: 1600px) {
        margin-top: -2.5rem;
    }

    @media only screen and (max-width: 1000px) {
        margin-top: -2rem;
        margin-left: 2rem;
        border-width: 0 0 0.1rem 0.1rem;
    }

    @media only screen and (max-width: 700px) {
        margin-top: -1rem;
        margin-left: 2rem;
    }
}

.normalBody{
    font-family: Quicksand;
    font-size: 3rem;
    font-weight: 50;

    @media only screen and (max-width: 1900px) {
        font-size: 2rem;
    }
    @media only screen and (max-width: 1600px) {
        font-size: 1.5rem;
    }

    @media only screen and (max-width: 1000px) {
        font-size: 1.3rem;
    }

    @media only screen and (max-width: 700px) {
        font-size: 0.8rem;
    }

    @media only screen and (max-width: 550px) {
        font-size: 1.1rem;
    }
}

.normalBodyAbout{
    margin: 3.5rem 6rem;
    text-align: left;
    color: #000000;

    @media only screen and (max-width: 1600px) {
        margin: 2rem 4rem;
    }

    @media only screen and (max-width: 700px) {
        margin: 1.1rem 2rem;
    }
}

.row8{

}

.hobbies{
    width: 30rem;
    border-radius: 50%;
    margin: 2rem;
    @media only screen and (max-width: 1900px) {
        width: 20rem;
    }

    @media only screen and (max-width: 1600px) {
        width: 15rem;
        margin: 1.3rem;
    }

    @media only screen and (max-width: 1000px) {
        width: 11.5rem;
    }

    @media only screen and (max-width: 800px) {
        width: 10rem;
    }

    @media only screen and (max-width: 700px) {
        width: 7rem;
        margin-top: 0;
        margin-bottom: 0;
    }

    @media only screen and (max-width: 550px) {
        width: 10rem;
        margin-top: 1.3rem;
        margin-bottom: 1.3rem;
    }
}


.arrowsRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    top: 3.3rem;
    height: 0;
}
.arrowAbout{
    @media only screen and (max-width: 550px) {
        width: 3rem;
        height: 3rem;
        border-color: black;
        border-style: solid;
        border-width: 0 0 0.1rem 0.1rem;
        margin: 2rem;
    }
}

.leftArrow{
    transform: rotate(45deg);
}
.rightArrow{
    transform: rotate(-135deg);
}

.leftArrow:hover{
    cursor: pointer;
    animation: leftarrow 0.6s infinite alternate ease-in-out;
}

.rightArrow:hover{
    cursor: pointer;
    animation: rightarrow 0.6s infinite alternate ease-in-out;
}

@keyframes leftarrow {
    0% { transform: translateX(0) rotate(45deg); }
    100% { transform: translateX(0.4em) rotate(45deg); }
}

@keyframes rightarrow {
    0% { transform: translateX(0) rotate(-135deg); }
    100% { transform: translateX(-0.4em) rotate(-135deg); }
}

.aboutCarousel{

    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media only screen and (max-width: 550px) {
        list-style-type: none;
        padding: 0;
        flex-direction: column;
        justify-content:center;
    }

}
.slideAbout{
    list-style: none;

    @media only screen and (max-width: 550px) {
        display: none;
    }

}

.slideAbout[data-active]{
    list-style: none;
    display: flex;
    justify-content: center;
}

.row9{
    display: flex;
    justify-content: center;
}

.scriptAbout{
    font-family: Great Vibes;
    font-size: 6.5rem;
    font-weight: 100;
    color: #91B1CF;
  
    @media only screen and (max-width: 1900px) {
      font-size: 5.5rem;
    }
  
    @media only screen and (max-width: 1600px) {
      font-size: 4.5rem;
    }
  
    @media only screen and (max-width: 1300px) {
      font-size: 3.5rem;
    }
  
    @media only screen and (max-width: 1000px) {
      font-size: 2.5rem;
      margin-top: 0;
    }
  
    @media only screen and (max-width: 700px) {
        font-size: 1.5rem;
    }
    
    @media only screen and (max-width: 550px) {
      font-size: 2.5rem;
    }
}